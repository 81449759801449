import { React, createContext, useContext, useState } from 'react'

const langs = ['en', 'es', 'ro']

const defaultState = {
  lang: process.env.GATSBY_LANG,
  setLang: () => {},
  toggleLang: () => {},
}

const LangContext = createContext(defaultState)

export const LangProvider = ({ children }) => {
  const [lang, setLangState] = useState(defaultState.lang)

  const setLang = (langToSet) => {
    // localStorage.setItem('lang', JSON.stringify(langToSet))
    setLangState(langToSet)
  }

  // const setLangNoLocalSave = (langToSet) => {
  //   setLangState(langToSet)
  // }

  const toggleLang = () => {
    // const index = langs.indexOf(lang)
    // const newIndex = index === langs.length - 1 ? 0 : index + 1
    // setLangState(langs[newIndex])
  }

  // useEffect(() => {
  //   // if (typeof window !== 'undefined') {
  //   //   const navLang = window.navigator.userLanguage || window.navigator.language
  //   //   // console.log(navLang)
  //   //   if (navLang?.slice(0, 2) === 'pl') setLangNoLocalSave('pl')
  //   //   else if (navLang?.slice(0, 2) === 'en') setLangNoLocalSave('en')
  //   // }
  //   const lsLang = JSON.parse(localStorage.getItem('lang'))
  //   if (lsLang === 'pl' || lsLang === 'en') setLangNoLocalSave(lsLang)
  // }, [])

  return (
    <LangContext.Provider value={{ lang, toggleLang, setLang }}>
      {children}
    </LangContext.Provider>
  )
}

export default LangContext

export const useLangContext = () => useContext(LangContext)
